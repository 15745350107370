$asset-font-path: '../../Fonts/';

// $OpenSans_Regular_Filename: 'OpenSans-Regular.ttf';
// $OpenSans_Bold_Filename: 'OpenSans-Bold.ttf';
// $OpenSans_SemiBold_Filename: 'OpenSans-SemiBold.ttf';
// $OpenSans_Medium_Filename: 'OpenSans-Medium.ttf';

// $OpenSans_Regular: 'Open Sans',sans-serif;
// $OpenSans_Bold: 'OpenSans-Bold',sans-serif;
// $OpenSans_SemiBold: 'OpenSans-SemiBold',sans-serif;
// $OpenSans_Medium: 'OpenSans-Medium',sans-serif;

// $SourceSans_Regular_Filename: 'SourceSansPro-Regular.ttf';
// $SourceSans_Bold_Filename: 'SourceSansPro-Bold.ttf';
// $SourceSans_SemiBold_Filename: 'SourceSansPro-SemiBold.ttf';

// $OpenSans_Regular: 'Source Sans Pro', sans-serif;
// $OpenSans_Bold: 'OpenSans-Bold',sans-serif;
// $OpenSans_SemiBold: 'OpenSans-SemiBold',sans-serif;
// $OpenSans_Medium: 'OpenSans-Medium',sans-serif;

@font-face {
    font-family: 'Source Sans Pro', sans-serif;
    src: url("../../Fonts/SourceSansPro-Regular.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Source Sans Pro', sans-serif;
    src: url("../../Fonts/SourceSansPro-SemiBold.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Source Sans Pro', sans-serif;
    src: url("../../Fonts/SourceSansPro-Bold.ttf") format("ttf");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Parisienne';
    src: url("../../Fonts/Parisienne-Regular.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Meddon';
    src: url("../../Fonts/Meddon-Regular.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dancing Script';
    src: url("../../Fonts/DancingScript-Regular.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dancing Script';
    src: url("../../Fonts/HerrVonMuellerhoff-Regular.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dancing Script';
    src: url("../../Fonts/SquarePeg-Regular.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url("../../Fonts/OpenSans-VariableFont_wdth\,wght.ttf") format("ttf");
    font-style: normal;
    font-display: swap;
}
// @font-face {
//     font-family: $OpenSans_Regular;
//     src: url($asset-font-path + $OpenSans_Regular_Filename) format('opentype');
// }

// @font-face {
//     font-family: $OpenSans_Bold;
//     src: url($asset-font-path + $OpenSans_Bold_Filename) format('opentype');
// }

// @font-face {
//     font-family: $OpenSans_SemiBold;
//     src: url($asset-font-path + $OpenSans_SemiBold_Filename) format('opentype');
// }

// @font-face {
//     font-family: $OpenSans_Medium;
//     src: url($asset-font-path + $OpenSans_Medium_Filename) format('opentype');
// }
