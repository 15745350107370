@import "../Colors/colors";
@import "../Mixins/index";
@import "../Mixins/fonts";
.sign_tab {
  text-transform: capitalize !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 0px !important;
}

.upload_box {
  border: 1px dashed #bebebe !important;
  margin: 40px 20px 20px 20px !important;
  padding: 20px !important;
  cursor: pointer !important;
}

.draw_typo {
  color: #252525 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  padding-left: 10px !important;
  margin-top: 10px !important;
}

.draw_sign_box {
  border-radius: 6px !important;
  border: 1px solid #979797 !important;
  background: #fff !important;
  display: flex !important;
  justify-content: center !important;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  width: 60%;
  height: 250px !important;
}

.add_media_typo1 {
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-decoration: underline !important;
  color: rgb(66, 130, 226) !important;
}

.add_media_typo2 {
  color: #252525 !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}
.add_media_icon {
  color: #4282e2 !important;
  text-align: center !important;
  font-family: Material Icons !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 26px !important;
}

.clear_button {
  border-radius: 3px !important;
  border: 1px solid #e30303 !important;
  color: #e30303 !important;
  background: rgba(255, 255, 255, 0) !important;
  padding: 7px 46px !important;
}
.clear_button_disabled {
  border-radius: 3px !important;
  border: 1px solid #e30303 !important;
  color: #e30303 !important;
  background: rgba(255, 255, 255, 0) !important;
  padding: 7px 46px !important;
  transition: none !important;
  &:hover {
    cursor: not-allowed !important;
  }
}

.sign_title {
  color: var(--grays-icons-black, #3b3b3b) !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  margin: 10px 10px 16px 36px !important;
}

.tap_here_typo {
  color: #3b3b3b !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
}

.tap_here_box {
  border-radius: 3px !important;
  border: 1px dashed #bebebe !important;
  padding: 20px !important;
  cursor: pointer !important;
  margin: 14px 20px 36px 0px !important;
}

.replace_pic {
  border-radius: 3px !important;
  border: 1px dashed #bebebe !important;
  padding: 20px !important;
  margin: 20px !important;
}

.accept_button {
  padding: 8px 56px !important;
  background: #4282e2;
}

.sign_images {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 4px !important;
  padding: 2px !important;
  cursor: pointer;
}

.img_width_height {
  width: 100%;
  aspect-ratio: 3/2 !important;
  height: 200px;
  mix-blend-mode: normal !important;
  object-fit: contain !important;
}

@media (max-width: 600px) {
  .img_width_height {
    width: 190px;
    height: 100% !important;
  }
  .draw_sign_box {
    width: 100%;
  }
}

.cancel_btn {
  @include text($Tufts-Blue, 14px, 700, 16px, normal);
  border-radius: 3px;
  padding: 10px 36px !important;
}

.clear_btn {
  @include text($secondary-white, 14px, 700, 16px, normal);
  background-color: $Tufts-Blue !important;
  border-radius: 3px;
  padding: 10px 36px !important;
  &:hover {
    background-color: $Tufts-Blue;
    @include text($secondary-white, 14px, 700, 16px, normal);
  }
}

.dialog_close_btn {
  cursor: pointer !important;
}
