@mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @mixin flex {
    display: flex;
  }

  @mixin flexCenterAll {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @mixin verticalCenter {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
  }

  @mixin commonLayoutWidth($width, $height, $padding, $margin) {
    width: $width;
    height : $height;
    padding: $padding;
    margin: $margin;
  }
  
  @mixin commonLayoutMobileWidth {
    width: 100%;
    margin: 0;
  }

  @mixin BgTexture {
    background-color: $secondary-white;
  }

  @mixin text( $color, $size, $weight, $height, $spacing) {
    font-family:'Source Sans Pro', sans-serif!important;
    font-size: $size !important;
    font-weight: $weight !important;
    line-height: $height !important;
    color: $color !important;
    letter-spacing: $spacing !important;
  }

  @mixin borderLayoutHeader {
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid $secondary-platinum;
    box-sizing: border-box;
  }
  @mixin defaultFont{
    font-family: 'Source Sans Pro', sans-serif!important;
  }