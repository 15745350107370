.PinturaRoot *{
    font-size: 16px !important;
}

.PinturaRoot[data-env~=landscape]>.PinturaNavMain button{
    width: 6em !important;
}

.PinturaButtonExport{
    width: 60px !important;
    height: 40px !important;
}

.PinturaButtonExport span span {
    font-size: 18px !important;
}
