@import '../Colors/colors';
@import '../Mixins/index';

.collhead_container {
  padding: 20px 19px 10px 19px !important;
  justify-content: space-between;
  cursor: pointer;
}

.sub_heading {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.coll_header {
  font-style: normal;
  font-weight: 700 !important;
  margin-left: 2px !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.updrop_icon {
  cursor: pointer;
  color: #4282e2 !important;
}
.drop_icon {
  cursor: pointer;
}

.coll_inner_div {
  min-height: 20px;
  padding: 18px 16px 29px 16px;
}

.coll_div {
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  width: 100%;
  min-height: 50px;
}

.coll_heading {
  width: 100%;
  padding: 14px 0px 10.3px 18px;
  font-style: normal;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #d5d5d5;
  color: #252525;
}

.coll_content {
  padding: 7.02px 15px 0px 29px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d5d5d5;
}

.coll_content_text {
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
}

.coll_content_text p{
  // cursor: pointer;
  // display: flex;
  // font-style: normal;
  // font-weight: 400;
  font-size: 16px;
  // line-height: 32px;
}

.div_header_rating {
  width: 28px;
  height: 26px;
  margin-left: 10px;
  margin-top: 0px;
  // background: #6dd400;
  border-radius: 3px;
}

.comment_play_icon{
  color: #ffffff !important;
  font-size: 25px;
  position: absolute;
  top: 45%;
  left: 40%;
  transform: translate(-50%, -50%);
}
.review_rating_square {
  width: 40px;
  height: 30px;
  display: flex;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.incomplete_linename{
  padding: 14px 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  span{
    @include text($Dark, 16px, 400, 32px, normal);
  }
  svg{
    @include text($Tufts-Blue, 20px, 400, 32px, normal); 
  }
}
.border_bottom{
  border-bottom: 1px solid #d5d5d5;
}
.spacing{
  padding-top: 25px;
}
.card_wrapper{
  max-height: 450px;
  overflow: scroll;
}
.right_padding{
  padding-right: 10px;
  padding-top: 5px;
}
.incomplete_temp{
  @include text($text-Black-Olive,14px, 600, 14px, normal); 
  margin-bottom: 10px;
  padding-left: 5px;
}