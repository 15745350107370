@import '../Colors/colors';
@import '../Mixins/index';

.sidemenu{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 35px 16px 20px 16px;
    height: calc(100vh - 80px) !important;
    overflow-y: hidden !important;
}
.company__logo{
    margin-left: 4px;
    margin-bottom: 20px;
}
.dynamic_logo{
    max-width: 166px;
    max-height: 24px;
}
.company_adress{
    margin-bottom: 10px;
    p:first-child{
        @include text($Mountain-Mist, 12px, 600, 24px, normal);
        @include defaultFont();
    }
    p:last-child{
        @include text($Dark, 14px, 600, 24px, normal);   
        @include defaultFont();     
    }
}
.progress__section{
    margin-bottom: 33px;
}
/* Styles for height up to 800px */
@media (max-height: 800px) {
    .menu__section{
        // max-height: 85vh;
        max-height: 60vh;
        overflow-y: scroll;
         scrollbar-width: none!important;
        -ms-overflow-style: none;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none!important;
        }
    }
  }
  
  /* Styles for height between 800px and 1000px */
  @media (min-height: 801px) and (max-height: 1000px) {
    .menu__section{
        // max-height: 85vh;
        max-height: 66vh!important;
        overflow-y: scroll;
         scrollbar-width: none!important;
        -ms-overflow-style: none;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none!important;
        }
    }
  }
  
  /* Styles for height 1000px and above */
  @media (min-height: 1001px) {
    .menu__section{
        // max-height: 85vh;
        max-height: 73vh;
        overflow-y: scroll;
         scrollbar-width: none!important;
        -ms-overflow-style: none;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none!important;
        }
    }
  }
  

.is__relative{
    position: relative;
}
.is__relative{
    p{
        position: absolute;
        z-index: 1;
        top: 5px;  
        @include defaultFont();
        font-weight: 600; 
    }
    P:first-child{
        left: 10px;
    }
    p:nth-child(2){
        right: 10px;
    }
    .curve{
        position: absolute;
        left: 0;
        top:0;
        height: 61px !important;
        z-index: 1;
    }
}
.overview__section,.report__section{
    p:first-child{
        @include text($Santa-Grey,12px, 700, 16px, normal);
        // text-transform: uppercase;
        margin-bottom: 1px;
    }
    .list__item{
        display: flex;
        align-items: center;
        gap: 13px;
        padding: 10px 16px 10px 16px;
        border-radius: 8px;     
        &:hover{            
            cursor: pointer;
        }
        svg{
            font-size: 20px;
        }
        .list__text{
            @include text($text-Black-Olive,14px, 600, 14px, normal); 
        }
    }
    // .list__item.active{
    //     background-color: $Tufts-Blue;
    //     color: $secondary-white!important;
    //     .list__text{
    //         @include text($secondary-white,14px, 600, 14px, normal); 
    //     }
    //     &:hover{            
    //         cursor: default;
    //     }
    // }
    .navlink.active .list__item{
        background-color: $Tufts-Blue;
        color: $secondary-white!important;
        .list__text{
            @include text($secondary-white,14px, 600, 14px, normal); 
        }
        &:hover{            
            cursor: default;
        }
    }
}

.border__list{
    border-left: 1px solid $border-grey;
}
.list__content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 3px 0px!important;
    
}
.list__item{
    display: flex;
    align-items: center;
    gap: 13px;
    
    // padding: 10px 16px 10px 16px;
    border-radius: 8px;
    .MuiListItemButton-root{
        padding: 6px 16px!important;
    }     
    &:hover{            
        cursor: pointer;
    }
    svg{
        font-size: 20px;
    }  
    span{      
        @include text($text-Black-Olive,14px, 600, 14px, normal); 
   }
    .MuiListItemIcon-root{
        min-width: 34px!important;
    }
    &:hover{
        div{
             background-color: transparent!important;
        }
       
    }
}
.navlink.active_template .list__item{
    background-color: $Tufts-Blue;
    color: $secondary-white!important;
   
    span{      
        @include text($secondary-white,14px, 600, 14px, normal); 
   }
    svg{
        color: $secondary-white!important;
    }
    &:hover{            
        cursor: default;
    }
}
.navlink.active_category .categories__list{
    background-color: $Tufts-Blue;
    color: $secondary-white!important;
    border-radius: 6px!important;
    margin-top: 4px;
    .list__content span{      
        @include text($secondary-white,14px, 600, 14px, normal); 
   }
    .how_to_use_this_form__content span{      
        @include text($secondary-white,14px, 600, 14px, normal); 
   }
    svg{
        color: $secondary-white!important;
    }
    &:hover{ 
        .list__content span{  
        @include text($secondary-white,14px, 600, 14px, normal);      
        cursor: pointer;
        color: $secondary-white!important;
        }
        .how_to_use_this_form__content span{      
            @include text($secondary-white,14px, 600, 14px, normal); 
       }
    }
}

.line__list__padding{
    padding-right: 16px!important;
    padding: 5px 0px 5px 0px;
}
.menu__categories__section{
    margin-left: 40px;
}
.menu__line__section{
    margin-left: 25px!important;
    opacity: 0!important;
    top: -100px!important;
    transition: opacity 0.6s ease!important;
}
.menu__line__section.ul_active{    
    opacity: 1!important;
    top: 0px!important;
}
.sticky{
    position: sticky;
    top: 0px;
}
// hide scrollbar
// *{
//     scrollbar-width: none!important;
//     -ms-overflow-style: none;
//     scrollbar-width: none; /* Firefox */
//     &::-webkit-scrollbar {
//       display: none!important;
//     }
// }
*{
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        -webkit-border-radius: 1ex;
    }
}
.categories__list .MuiButtonBase-root{
    padding: 0px 16px;
    margin :4px 0px;
    span:first-child{
        @include text($text-Black-Olive,14px, 400, 14px, normal); 
        // transition: color 0.1s ease-in;  
    }
    span:nth-child(2){
        @include text($text-Black-Olive,14px, 600, 14px, normal); 
        // transition: color 0.1s ease-in;  
    }
    .how_to_use_this_form__content{
        // padding: 0px 16px;
        margin :3px 0px;
    }
    .how_to_use_this_form__content span{
        @include text($primary-color,14px, 400, 14px, normal); 
    }
    
    &:hover{
        background-color: transparent;
        span:first-child{
             @include text($Tufts-Blue,14px, 400, 14px, normal); 
        }
        span:nth-child(2){
            @include text($Tufts-Blue,14px, 600, 14px, normal); 
        }
        .how_to_use_this_form__content span{
            @include text($Tufts-Blue,14px, 400, 14px, normal); 
        }
    }
}
.menu__line__list .MuiListItemButton-root{
    padding: 1px 0px 1px 22px;
}
.menu__line__list .MuiButtonBase-root{
    span:first-child{
        @include text($text-Black-Olive,14px, 400, 14px, normal); 
        padding-left: 18px;
        transition: color 0.3s ease-in;  
    }
    span:nth-child(2){
        @include text($text-Black-Olive,14px, 600, 14px, normal); 
        transition: color 0.3s ease-in; 
        svg{
            transition: color 0.3s ease-in; 
            color: $primary-Jungle-Green;
            font-size: 16px;
        }
    }
    &:hover{
        background-color: transparent;
        span:first-child{
             @include text($Tufts-Blue,14px, 400, 14px, normal); 
        }
        span:nth-child(2){
            @include text($Tufts-Blue,14px, 600, 14px, normal); 
            svg{
                color: $Tufts-Blue;
            }
        }
    }
}
.navlink.active_line .menu__line__list .list__content{
    padding: 5px 0px 5px 0px;
    background-color: $Tufts-Blue;
    color: $secondary-white!important;
    border-radius: 6px!important;
    span{      
        @include text($secondary-white,14px, 600, 14px, normal); 
   }
    svg{
        color: $secondary-white!important;
    }
    &:hover{ 
        span{  
        @include text($secondary-white,14px, 600, 14px, normal);      
        cursor: pointer;
        color: $secondary-white!important;
        }
    }
}
.portal__link{    
    display: flex; 
    gap: 5px;
    align-items: center;
    padding: 6px 16px;
    margin-top: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    p,a{
        @include text($Tufts-Blue,14px, 600, 14px, normal);
        text-decoration: none!important;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    svg{
        color: $Tufts-Blue;
        font-size: 18px;
    }
}
.sidemenu__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(9, 36, 75, 0.02);
    box-shadow: inset 0px 1px 0px #F2F4F6;
    border-radius: 0px 0px 0px 8px;
    padding: 20px;
    div{
        display: flex;
        gap: 16px;
        p:first-child{
            @include text($Oxford-Blue,14px, 600, 20px, normal); 
        }
        p:nth-child(2){
            @include text($Cool-Grey,12px, 400, 18px, normal); 
        }
        .footer_name{
            display: flex;
            flex-direction: column;
            align-items: baseline;
            gap: 2px;
        }
    }
    svg{
        color: $Silver-Sand!important;
    }
}

.DuplicateIcon svg {
    color:$primary-Spanish-Gray !important;
    margin-right: 5px;
    font-size: 16px;
    &:hover{
    color: $Tufts-Blue !important;
    }
}
.DuplicateIcon img {
    color:$primary-Spanish-Gray;
    margin-right: 5px;
    height: 16px;
    &:hover{
        color: $Tufts-Blue;
        }
}

.DeleteIcon svg{
    color: $severity-Electric-Red !important;
    margin-left: 5px;
    font-size: 16px;
}

.sidemenu_add_category_button{
    display:flex;
    margin:10px 10px 10px 10px;
    cursor: pointer;
    svg{
        color: $Tufts-Blue;
        font-size:16px;
    }
}
@media only screen and (max-width: 900px) {
    .menu__section{
        max-height: unset!important;
        overflow-y: auto !important;
    }
}

.fade-in {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .fade-in.active {
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes pulse {
    0%{
        opacity: 0;
      } 
      100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
.pulse-once {
    animation: pulse 2s ease-in-out 1; /* Adjust duration as needed */
  }

@keyframes fadeOut {
    0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        height: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
  }

  .deleted-category {
    animation: fadeOut 2s ease;
  }

  .animation-Category {
    height: 0;
    width: 100px;
    // animation: increaseHeight 2s forwards; /* Adjust the duration as needed */
}
  .animation-line {
    height: 0;
    width: 100px;
    animation: increaseLineHeight 0.5s forwards; /* Adjust the duration as needed */
}
  .animation-DeleteCategory {
    height: 18;
    width: 100px;
    animation: decreseLineHeight 0.5s forwards; /* Adjust the duration as needed */
}
 
@keyframes decreseLineHeight {
    from {
        height: 18px;
    }
    to {
        height: 0;
    }
}
@keyframes increaseHeight {
    from {
        height: 0;
    }
    to {
        height: 20px;
    }
}

@keyframes increaseLineHeight {
    from {
        height: 0;
    }
    to {
        height: 20px;
    }
}

.duplicated_category{   
    width : 100%;   
    background-color : white;
    border-radius: 2px;
    /* Initial background color */
    animation: changeColor 2s linear;
    /* Animation name, duration, and timing function */
    }
    @keyframes
    changeColor {   
        0%{    
        background-color: white;
    /* Start color */
        }   
        100% {    
    background-color : #e4edfb;
    /* End color */
      } }
.duplicated_line{   
    width : 100%;   
    display: flex;
    background-color : white;
    border-radius: 2px;
    /* Initial background color */
    animation: changeColor 2s linear;
    /* Animation name, duration, and timing function */
    }
    @keyframes
    changeColor {   
        0%{    
        background-color: white;
    /* Start color */
        }   
        100% {    
    background-color : #e4edfb;
    /* End color */
      } }

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary{
    background-color: #eeeeee !important;
}
  