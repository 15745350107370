@import "../Colors/colors";
@import "../Mixins/index";

.ai-modal-main {
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: hidden;
    width: 1px;
  }
}

.ai_comment_modal_container {
  gap: 6px;
  top: 50px;
  left: 50%;
  position: relative;
  width: 900px !important;
  padding: 20px 0px 0px 0px;
  outline: none !important;
  margin-bottom: 5%;
  transform: translate(-50%, -0%);
  max-height: 760px !important;
}

.ai_modal_close_cross {
  cursor: pointer;
  float: right !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  color: $Santa-Grey !important;
  line-height: normal !important;
  margin-right: 20px !important;
}

.ai_title {
  @include text($text-Black-Olive, 20px, 600, 24px, normal);
  margin-top: 18px !important;
}
.ai_subtitle {
  @include text($text-Black-Olive, 15px, 400, 23px, normal);
  text-align: center !important;
}

.center_align {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ai_subdiv {
  background-color: $CulturedF8 !important;
  border-radius: 20px !important;
  gap: 8px;
  padding: 14px;
}

.ai_desc {
  @include text($text-Black-Olive, 13px, 400, 20px, normal);
}

.ai_remain {
  @include text($text-Black-Olive, 15px, 400, 24px, normal);
  margin-top: 20px !important;
  text-align: right !important;
}

.ai_cancel_button {
  @include text($text-Black-Olive, 14px, 600, 20px, normal);
  height: 36px !important;
  width: 73px !important;
  border-radius: 2px;
  border: 1px solid #d5d5d5 !important;
  float: right !important;
  color: #454545 !important;
  text-transform: none !important;
}

.ai_use_comment_button {
  @include text($text-Black-Olive, 14px, 600, 20px, normal);
  height: 36px !important;
  margin-left: 8px !important;
  width: 115px !important;
  border-radius: 2px;
  background-color: #4282e2 !important;
  float: right !important;
  color: #ffffff !important;
  text-transform: none !important;
}
.ai_use_comment_button:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
  border: 1px solid $secondary-Smoke-White !important;
}

.ai_generate_button {
  @include text($text-Black-Olive, 14px, 600, 20px, normal);
  height: 36px !important;
  width: 161px !important;
  margin-top: 16px !important;
  float: right !important;
  border-radius: 2px;
  background-color: #4282e2 !important;
  color: #ffffff !important;
  text-transform: none !important;
}

.ai_generate_button:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
  border: 1px solid $secondary-Smoke-White !important;
}

.ai_beta_button {
  height: 20px !important;
  width: 30px !important;
  border-radius: 33px !important;
  font-size: 10px !important;
  padding: 4px 0px 4px 0px !important;
  background-color: #b620e0 !important;
  color: #ffffff !important;
  margin-left: 240px !important;
  margin-top: 20px !important;
  cursor:default !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.p_setting {
  @include text($text-Black-Olive, 14px, 400, 24px, normal);
  margin-left: -5px !important;
}

.recent_history_title {
  @include text($text-Black-Olive, 18px, 600, 24px, normal);
}

.switch_to_this_comment{
  @include text($primary-color, 15px, 600, 24px, normal);
  text-transform: none !important;
  margin-top: 5px !important;
}
