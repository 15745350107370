@import "./Assets/Styles/Mixins/fonts";
@import "./Assets/Styles/Colors/colors";
@import "./Assets/Styles/Mixins/index";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Meddon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Square+Peg&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
*,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}
body {
  overflow: hidden !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 13px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}
p {
  font-size: 13px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}
.app_container {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: auto !important;
  @media (max-width: 768px) {
    overflow-y: auto !important;
  }
}
.main__container {
  width: 100%;
  max-width: 1600px;
  height: 100vh !important;
}
.page-background {
  background-color: $backgroundGrey;
  min-height: 100vh;
  overflow: hidden !important;
}
.component {
  padding: 0px 2px;
  background-color: $secondary-white;
}
.h_100 {
  min-height: 100vh;
  border-radius: 8px !important;
}
.flex_props {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  overflow: auto;
}
.page__layout {
  padding-top: 40px !important;
  padding-right: 40px !important;
}
.mobile_page_layout {
  padding-top: 80px !important;
}
.w_bg {
  background: $secondary-white !important;
}
.bordered__text {
  border: 1px solid $border-grey !important;
  border-radius: 9px !important;
  padding: 12px 15px;
  margin-bottom: 13px !important;
  position: relative;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
    background-color: $Cyan-Blue !important;
  }
}

.add_dropdown__text {
  border-left: 1px solid $border-grey !important;
  border-right: 1px solid $border-grey !important;
  border-bottom: 1px solid $border-grey !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  padding: 12px 15px;
  margin-bottom: 13px !important;
  position: relative;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
    background-color: $Cyan-Blue !important;
  }
}
.bordered__text__content {
  @include text($Outer-Space, 17px, 400, 21px, normal);
}
.delete_modal_pdf_icon svg {
  margin-bottom: -4px;
  margin-right: 2px;
}
.rating_bordered__text__content {
  margin-right: 20px;
  @include text($Outer-Space, 17px, 400, 21px, normal);
}
.bordered__text__content2 {
  @include text($Outer-Space, 17px, 600, 21px, normal);
}
.loader_with_text {
  flex-direction: column;
  gap: 5px;
}

.loading_text:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

.close-wrapper-category {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.galleryModal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  align-items: center;
  justify-content: center;
  scrollbar-width: thin;
  width: 100%;
}

.gallery-modal-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 712px !important;
  scrollbar-width: thin;
  width: 100%;
  height: 91vh;
  overflow-y: scroll;
  position: relative;
  max-height: 610px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.delete_confirm_btn {
  background-color: #E30303 !important;
  width: 318px !important;
  height: 48px !important;
  font-size: 14px !important;
  font-family:  "Source Sans Pro", sans-serif !important;
  border-radius: 3px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  letter-spacing: 0.5px !important;
  box-shadow: none !important;
  &:hover {
    background-color: #E30303 !important;
  }
}

.delete_cancel_btn {
  width: 48px !important;
  height: 29px !important;
  border-radius: 3px !important;
  transition: none !important;
  &:hover {
    transition: none !important;
    background-color: inherit !important;
  }
  &:focus {
    transition: none !important;
    background-color: inherit !important;
  }
  &:active {
    transition: none !important;
    background-color: inherit !important;
  }
}

.custom-underline {
  position: relative !important;
  display: inline-block !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  font-family: "Open Sans" !important;
  letter-spacing: 0.5px !important;
  line-height: 24px !important;
  color: var(--Icon-grey, #777) !important;
}

.custom-underline::after {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -4px !important;
  width: 100% !important;
  height: 2px !important;
  background-color: var(--Icon-grey, #777) !important;
}

.close_btn {
  color: #a5a5a5 !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
