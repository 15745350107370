@import "../Colors/colors";
@import "../Mixins/index";

.filter-tags-lable,
.filter-tags-lable span {
  @include text($Outer-Grey, 14px, 400, 20px, normal);
}

.filter-tags {
  border-radius: 35px !important;
  border: 1px solid $secondary-Outer-Grey !important;
}

.filter-tags-textfield,
.filter-tags-textfield span {
  @include text($Outer-Grey, 14px, 400, 20px, normal);
}

.filter-buttongrp-textfield,
.filter-buttongrp-textfield span {
  @include text($Outer-Space, 12px, 400, 20px, normal);
}

.filter-button-group-sub-container {
  height: 36px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-right: 1px solid $border-grey;
}

.filter-button-group-sub-lastchildcontainer {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.filter-button-group-container {
  margin-left: 8px !important;
  border-radius: 4px !important;
  border: 1px solid $border-grey !important;
}

.filter-button-tab {
  height: 36px !important;
  text-transform: none !important;
  padding: 0px 11px 0px 11px !important;
  border-right: 1px solid $border-grey !important;
}
.filter-button-tab-last-child {
  height: 36px !important;
  text-transform: none !important;
  padding: 0px 11px 0px 11px !important;
}
.filter-button-tab-selected {
  height: 36px !important;
  color: #fff !important;
  border-radius: 4px !important;
  text-transform: none !important;
  padding: 0px 11px 0px 11px !important;
  background-color: $primary-color !important;
  border-right: 1px solid $border-grey !important;
}
.filter-buttongrp-selected-textfield,
.filter-buttongrp-selected-textfield span {
  @include text(#fff, 12px, 400, 20, normal);
}

::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff !important;
  border-radius: 8px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 8px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
  border-radius: 8px !important;
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  /* Your CSS rules for this range of screen sizes */
  /* For example, you could change the background color */
  .filter-button-tab {
    height: 36px !important;
    text-transform: none !important;
    padding: 0px 2px 0px 2px !important;
    border-right: 1px solid $border-grey !important;
  }
  .filter-button-tab-last-child {
    height: 36px !important;
    text-transform: none !important;
    padding: 0px 2px 0px 2px !important;
  }
  .filter-button-tab-selected {
    height: 36px !important;
    color: #fff !important;
    border-radius: 4px !important;
    text-transform: none !important;
    padding: 0px 2px 0px 2px !important;
    background-color: $primary-color !important;
    border-right: 1px solid $border-grey !important;
  }

  /* You can add more rules as needed */
}