@import "../Colors/colors";
@import "../Mixins/index";

.image_container {
  height: 125px !important;
  width: 155px !important;
  object-fit: cover !important;
  cursor: pointer;
}

.video_container {
  height: 125px !important;
  width: 155px !important;
  position: relative !important;
  margin-top: 2px !important;
  margin-bottom: 68px !important;
}

.video_play_icon {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  color: white !important;
  font-size: 50px !important;
  opacity: 0.7 !important;
}

.caption_container {
  background-color: $Grey-opc;
  border-radius: 0px 0px 2px 2px;
  height: 31px;
  padding: 8px;
  width: 155px;
  margin-top: -3px;
  color: $text-Black-Olive;
  text-overflow: hidden;
  overflow: hidden;
}
.caption_text{
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15.08px;
    color: $text-Black-Olive;
}
