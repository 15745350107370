@import "../Colors/colors";
@import "../Mixins/index";
@import "../Mixins/fonts";

.column_typo1 {
  color: #7e7e7e !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  letter-spacing: 0.5px !important;
}

.column_typo2 {
  color: #424242 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  letter-spacing: 0.5px !important;
}

.column_typo3 {
  color: #424242 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: -0.25px !important;
}
.table_cell_border1 {
  border: 0.5px solid #eaeaea !important;
  color: #7e7e7e !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  padding: 13px 13px 13px 22px !important;
  width: 498px !important;
  background-color: #f9f8f9 !important;
  height: 44px !important;
  border: 0.5px solid var(--neutral-grey-3, #eaeaea) !important;
  background: var(--neutral-grey-1, #f9f8f9) !important;
}

.table_cell_border2 {
  height: 44px !important;
  padding: 13px 13px 13px 22px !important;
  border: 0.5px solid var(--neutral-grey-3, #eaeaea) !important;
  background: var(--neutral-grey-1, #f9f8f9) !important;
}

.table_cell_border3 {
  padding: 11px !important;
  border: 0.5px solid var(--neutral-grey-3, #eaeaea) !important;
  background: var(--neutral-white, #fff) !important;
}

.table_cell_border4 {
  padding: 11px !important;
  height: 44px !important;
  border: 0.5px solid var(--neutral-grey-3, #eaeaea) !important;
  background: var(--neutral-white, #fff) !important;
}
.info_icon {
  font-size: 11px !important;
  color: #9b9b9b !important;
}

.badge_info .MuiBadge-anchorOriginTopRight {
  right: -6px !important;
}
