@import "../Colors/colors";
@import "../Mixins/index";
.isFlex {
  display: flex;
  align-items: center;
}
.header-search-wrapper .header-search-bar {
  width: 96% !important;
  border: 1px solid $border-grey;
  border-radius: 20px;
  margin: 23px 20px 16px 18px;
  .MuiInputBase-root,
  input {
    width: 100%;
    min-height: 25px !important;
  }
}
.line__content__head {
  border-top: 1px solid $border-grey;
  border-bottom: 1px solid $border-grey;
  min-height: 64px;
  padding: 17px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .lineArrow_icon {
    font-size: 24px;
    color: $Santa-Grey;
    cursor: pointer;
  }
}
.line__head__text {
  @include text($Dark, 20px, 400, 24px, normal);
  &:hover{            
    cursor: pointer;
}
}
.line__content {
  padding: 17px 24px;
  border-bottom: 1px solid $border-grey;
  .linecontent_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover{
      cursor: pointer!important;
    }
  }
  .line__content__text {
    display: flex;
    align-items: center;
    gap: 9px;
    @include text($Tufts-Blue, 20px, 400, 24px, normal);
    svg {
      font-size: 20px !important;
      color: $Tufts-Blue;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .line__content__text.line__incomplete {
    @include text($Dark, 20px, 400, 24px, normal);
  }
  .lineArrow_icon {
    font-size: 24px;
    color: $Santa-Grey;
    cursor: pointer;
  }
  .linecontent_body {
    margin-top: 9px;
    margin-left: 28px;
    margin-right: 30px;
    display: flex;
    gap: 17px;
  }
  .line_content_comment,
  .line_content_photo {
    border: 1px solid $secondary-Gray !important;
    min-width: 140px;
    display: flex;
    align-items: center;
    gap: 13px;
    border-radius: 3px;
    padding: 0px 11px;
    cursor: pointer;
  }
  .line_content_comment.responsive,
  .line_content_photo.responsive {
    min-width: 10px;
    justify-content: center;
  }
  .linecontent_details {
    margin-top: 16px;
    min-height: 42px;
    background-color: $Lime-Gray;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 9px 13px;    
    flex-wrap: wrap;
    margin-left: 28px;
    margin-right: 30px;
  }
  .rating_square_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    .rating_square {
      width: 18px;
      height: 18px;
      display: flex;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 11px;
        height: 11px;
        color: $secondary-white;
      }
    }
    .rating_name_text{
      @include text($text-Black-Olive, 14px, 400, 17px, normal);
    }
  }
}
.media_text{
   @include text($text-Black-Olive, 14px, 400, 17px, normal);
 }

.done_icon.visible{
   visibility: visible;
}
.done_icon.hidden{
   visibility: hidden;
}
.close-wrapper-new-line {
  svg {
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
    color: #3b3b3b !important;
  }
}
.line_media_spacing{
   gap: 6px;
}
.additionalInput{
  margin-top: 19px;
  margin-left: 28px;
  margin-right: 30px;
  p{
    padding-left: 5px;
    font-size: 16px;
    color: $text-Black-Olive;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 900px) {
  .header-search-wrapper .header-search-bar {
    width: 90% !important;
  }
  .line__content {
    padding: 17px 10px;
  }
  .linecontent_body {
    padding-right: 18px;
  }
}
@media only screen and (max-width: 500px) {
  .sc-kGFDUS{
    flex: auto 0 1!important;
  }
  .sc-ACYlI{
    width: 70px!important;
  }
  .sc-khsqcC{
    align-items: center!important;
  }
  .sc-WKhSL{
    max-width: 80%!important;
  }
}
.no_des_flex{
  display: flex;
  align-items: center;
  gap: 6px;
}
.btn_height{
  height: 36px!important;
  margin-left:10px;
}