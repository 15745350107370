@import "../Colors/colors";
@import "../Mixins/index";

.inspection__title {
  @include text($primary-Spanish-Gray, 12px, 700, 16px, normal);
  margin: 40px 0px 10px 15px !important;
  text-transform: uppercase;
}
.progress__section__category {
  border-bottom: 1px solid $border-grey !important;
  .MuiLinearProgress-root {
    min-height: 65px !important;
    border-radius: 0px !important;
  }
  .MuiLinearProgress-colorPrimary {
    background-color: $secondary-white !important;
  }
}
.border-top {
  border-top: 1px solid $border-grey !important;
}
.menu__section {
  height: calc(100vh - 155px - 155px) !important;
  max-height: 85vh;
  overflow-y: scroll;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.inspectionBox {
  overflow-y: auto !important;
  height: calc(100vh - 95px) !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.new__relative {
  overflow: hidden !important;
  display: flex;
  position: relative;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  p {
    @include defaultFont();
    font-weight: 600;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-style: normal;
    padding-left: 24px;
    padding-right: 10px;
  }
  .MuiLinearProgress-root {
    position: unset !important;
    overflow: hidden !important;
  }
}
.how_to_use_this_form {
  overflow: hidden !important;
  display: flex;
  position: relative;
  align-items: center;
  max-height: 32px;
  padding-left: 60px;
  &:hover {
    cursor: pointer;
  }
  p {
    @include defaultFont();
    font-weight: 600;
    @include text($primary-color, 14px, 400, 24px, normal);
    padding-left: 24px;
    padding-right: 10px;
    display: contents;
  }
  .MuiLinearProgress-root {
    position: unset !important;
    overflow: hidden !important;
  }
}

.listingCatergory {
  text-align: end;
  padding-left: 24;
  padding-right: 10;
}
.edit_category {
  align-items: center;
  min-height: 65px !important;
  display: flex;
  justify-content: space-between;
  p {
    // position: absolute;
    z-index: 1;
    top: 21px;
    @include defaultFont();
    @include text($Dark, 18px, 400, 24px, normal);
  }
  P:first-child {
    padding-left: 24px;
  }
  p:nth-child(2) {
    right: 10px;
  }
  .curve {
    // position: absolute;
    left: 0;
    top: 0;
  }
}

.dialog-container-edit-description .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 650px !important;
  width: 100%;
  // padding: 9px 28px 10px 9px;
}

.progress__section__category .is__relative {
  p {
    // position: absolute;
    z-index: 1;
    top: 21px;
    @include defaultFont();
    @include text($Dark, 18px, 400, 24px, normal);
  }
  P:first-child {
    left: 24px;
  }
  p:nth-child(2) {
    right: 10px;
  }
  .curve {
    // position: absolute;
    left: 0;
    top: 0;
  }
}
.add__category {
  padding: 25px 15px 30px 15px;
  button {
    height: 55px !important;
  }
}
.category__arrow_right svg {
  color: $Santa-Grey !important;
  font-size: 32px;
}
.how_to_use_this_form__arrow_right svg {
  color: $primary-color !important;
  font-size: 18px;
}
.pointer:hover {
  cursor: pointer;
}
// add category modal scss
.dialog-container-category .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 870px !important;
  width: 100%;
  // padding: 9px 28px 10px 9px;
}
.category-dialog-title {
  padding: 36px 36px 5px 36px !important;
  text-align: center;
  @include text($Dark, 18px, 600, 24px, normal);
}
.category-dialog-subtitle {
  text-align: center;
  @include text($Sonic-Silver, 14px, 400, 21px, normal);
  font-style: italic;
  margin-bottom: 5px !important;
}
.dialog-container-category h2 {
  font-size: 22px !important;
}
.close-wrapper-category {
  svg {
    position: absolute;
    top: 7px;
    right: 12px;
    cursor: pointer;
    color: $Santa-Grey !important;
  }
}
.dialog-content-category {
  margin-top: 2px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  // padding: 20px 10px 20px 27px !important;
  .input-form-label {
    color: $primary-Sonic-Silver;
    opacity: 57%;
    text-transform: uppercase;
    margin-bottom: 5px;
    span {
      color: $severity-Electric-Red;
    }
  }
}
.forms-control-category {
  width: 100% !important;
  margin-bottom: 25px !important;
}
.attachement_modal__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px !important;
  line-height: 30px !important;
  color: $title-charcoal;
}
.category-modal-action {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 27px !important;
  padding-bottom: 25px !important;
}
.catIsFlex {
  display: flex;
  gap: 16px;
  svg {
    color: $Ocean-Boat-Blue;
  }
}
.in_inspection {
  margin-bottom: 11px !important;
  @include text($Sonic-Silver, 13px, 400, 16px, normal);
}
.cat_seleted_icon {
  display: none !important;
}
.bordered__text.cat_selected {
  border: 1px solid $primary-color !important;
  background-color: $Cyan-Blue !important;
  .cat_seleted_icon {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 24px !important;
    color: $primary-color;
    display: block !important;
  }
}
.bordered__text.cat_selected2 {
  border: 1px solid $primary-color !important;
  background-color: $Cyan-Blue !important;
  padding-right: 35px;
  .cat_seleted_icon {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 24px !important;
    color: $primary-color;
    display: block !important;
  }
}
// create new category modal scss
.dialog-container-new-category .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 430px !important;
  width: 100%;
  // padding: 9px 28px 10px 9px;
}
.dialog-container-add-template .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 360px !important;
  width: 100%;
  // padding: 9px 28px 10px 9px;
}
.dialog-container-new-category h2 {
  font-size: 22px !important;
}
.close-wrapper-new-category {
  svg {
    position: absolute;
    top: 17px;
    left: 20px;
    cursor: pointer;
    color: $text-Black-Olive !important;
  }
}
.close-wrapper-new-category-right {
  svg {
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
    color: $text-Black-Olive !important;
  }
}
.dialog-content-new-category {
  margin-top: 2px;
  padding-left: 40px !important;
  padding-right: 34px !important;
  .input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 8px;
    span {
      color: $severity-Electric-Red;
    }
  }
}
.w-100 {
  width: 100%;
  input {
    height: 28px;
  }
}
.add_new_category_checkbox {
  margin-top: 24px !important;
  .MuiFormControlLabel-label {
    @include text($Charcoal-Grey, 16px, 400, 20px, normal);
  }
}
.new-category-dialog-title {
  padding: 36px 34px 5px 39px !important;
  text-align: center;
  @include text($Dark, 18px, 600, 24px, normal);
  margin-bottom: 33px !important;
}
.new-category-modal-action {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 36px 34px 25px 39px !important;
  button {
    width: 100%;
  }
}
.edit_container_icons {
  display: flex;
  gap: 19px;
  span {
    display: flex;
    justify-content: center;
    height: 32px;
    width: 35px;
    padding: 5px;
    background-color: $Anti-Flash-White;
    border-radius: 5px;
  }
  .edit__icon {
    color: $text-Black-Olive;
  }
  .delete__icon {
    color: $severity-Electric-Red;
  }
}

.sun-editor-editable * {
  font-family: "Source Sans Pro", sans-serif !important;
}

.intro_drop_icon {
  cursor: pointer;
  color: #979797 !important;
  height: 24px !important;
  width: 24px !important;
}

.intro_collhead_container {
  padding: 16px 8px 8px 16px !important;
  justify-content: space-between;
  color: #979797 !important;
  cursor: pointer;
}

.category__moreicon_transparent {
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 3px;
}
.category__moreicon {
  background-color: #F8F9FB;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 3px;
}

.line__moreicon {
  background-color: #F8F9FB;
  width: 65px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // margin-right: 8px;
  border-radius: 3px;
}
.line__narrative_moreicon {
  background-color: #F8F9FB;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // margin-right: 8px;
  border-radius: 3px;
}

.category-custom-menu .MuiPaper-root {
  box-shadow: none;
  background-color: #FFFFFF;
  border-radius: 3px;
}

.category_color_indicator {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
}

@keyframes highlightBlue {
  0% {
    background-color: #e4edfb;
  }
  100% {
    background-color: transparent;
  }
}

.highlight-blue {
  animation: highlightBlue 2s ease-in-out;
}

.print_off{
  color:#545454 !important;
  height: 18px;
  width: 18px;
}

.add_template_dropdown_title{
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-decoration-skip-ink: none;
  color:#3B3B3B;
  padding-bottom: 6px;

}