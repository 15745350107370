@import '../Colors/colors';
@import '../Mixins/index';

.header{
    display: flex;
    align-items: center;
    z-index: 9;
    justify-content: space-between;
    padding: 17px 24px;
    box-shadow:0px 2px 6px -2px rgba(110, 110, 110, 0.5);
    min-height: 66px;
    .header__name{
        @include text($text-Black-Olive, 18px, 600, 24px, normal);
    }
    .header__title{
      display: flex;
      @include text($text-Black-Olive, 18px, 600, 24px, normal);
      gap: 25px;
      align-items: center;
      svg{
        cursor: pointer;
      }
    }
    .header__action{
      display: flex;
      align-items: center;
      gap: 5px;
      button{
        min-width: 20px;
        &:hover{
          background: transparent;
        }
        svg{
          color: $Dark;
        }
      }
    }
    .header__icon{
         background-color: $backgroundGrey;
         padding: 10px 10px 7px 10px;
         border-radius: 50%;
         position: relative;
    }
  .header__badge .MuiBadge-badge{
    background-color: $severity-Electric-Red;
    color: $secondary-white;
  }
}
.sticky_header{
    position: sticky;
    top: 0;
    display: flex;
    background-color: white;
    align-items: center;
    z-index: 9;
    justify-content: space-between;
    padding: 17px 24px;
    box-shadow:0px 2px 6px -2px rgba(110, 110, 110, 0.5);
    min-height: 66px;
    .header__name{
        @include text($text-Black-Olive, 18px, 600, 24px, normal);
    }
    .header__title{
      display: flex;
      @include text($text-Black-Olive, 18px, 600, 24px, normal);
      gap: 25px;
      align-items: center;
      svg{
        cursor: pointer;
      }
    }
    .header__action{
      display: flex;
      align-items: center;
      gap: 5px;
      button{
        min-width: 20px;
        &:hover{
          background: transparent;
        }
        svg{
          color: $Dark;
        }
      }
    }
    .header__icon{
         background-color: $backgroundGrey;
         padding: 10px 10px 7px 10px;
         border-radius: 50%;
         position: relative;
    }
  .header__badge .MuiBadge-badge{
    background-color: $severity-Electric-Red;
    color: $secondary-white;
  }
}

.header__menu{
  .MuiPaper-root{
    min-width: 180px;
  }
  .MuiList-root{
    padding-top: 28px!important;
  }
  .list__close_icon{
    position: absolute;
    top: 3px;
    right: -5px;
  }
  .MuiListItemText-root span{
    @include text($Outer-Space, 14px, 400, 24px, normal);
  }
  .MuiListItemIcon-root{
      min-width: 46px!important;
   svg{
    @include text($Outer-Space, 24px, 400, 24px, normal);
    }
  }
}
.header_info_text{
  @include text($Tufts-Blue, 14px, 600, 24px, normal); 
  text-transform: uppercase;
  cursor: pointer;
}