@import '../Colors/colors';
@import '../Mixins/index';

.title{
    @include text($Dark, 20px, 600, 32px, normal);
    margin-top: 58px;
}
.subtitle{
    @include text($Charcoal-Grey, 16px, 400, 22px, normal);
    margin-bottom: 23px!important;
}
.attachment__wrapper{
    margin-bottom: 12px;
}
.attachment-dialog-title{
    text-align: center;
    @include text($Dark, 18px, 600, 24px, normal);
}
.attachement__content__container{
    margin-top: 13px!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid $border-grey!important;
}
.attachment__content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2px
}
.attachement__name{
    @include text($Dark, 18px, 400, 24px, normal);
}
.attachement__image{
    @include text($Tufts-Blue, 16px, 400, 24px, normal);
}
.attach__actions{
    display: flex;
    align-items: center!important;
    justify-content: center!important;
    padding: 0px 10px 20px 27px !important;
    button{
        width: 100%!important;
        height: 48px!important;
        @include text($secondary-white, 14px, 700, 26px, normal);
    }
}
.attachment__actions{
    display: flex;
    justify-content: space-between;
    gap: 15px;
    svg{
        font-size: 18px;
        &:hover{
            cursor: pointer;
        }
    }
    .edit__icon{
        color: $secondary-Gray!important;        
    }
    .delete__icon{
        color: $severity-Electric-Red!important;
        opacity: 0.5;
        transition: opacity 0.3s ease;
        &:hover{
            opacity: 1;
        }
    }
}
.add_attachment{
    margin-top: 21px;
    display: flex;
    gap: 8px;
    align-items: center;
    svg{
        color: $Tufts-Blue;
        font-size: 18px;
        &:hover{
            cursor: pointer;
        }
    }
    span{
        @include text($Dark, 16px, 400, 22px, normal); 
        &:hover{
            cursor: pointer;
        }
    }
   
}
.dialog-container-attachment .MuiPaper-elevation.MuiPaper-rounded {
    max-width: 431px!important;
    width: 100%;
    padding: 9px 28px 10px 9px;
}
.dialog-container-attachment h2 {
    font-size: 22px !important;
}
.close-wrapper-attachment {
    svg {
        position: absolute;
        top: 17px;
        right: 17px;
        cursor: pointer;
        color: $Santa-Grey!important;
    }
}
.attach_label{
    text-transform: capitalize!important;
    @include text($Sonic-Silver, 13px, 400, 18px, normal); 
}
.dialog-content-attachment {
    margin-top: 2px;
    padding: 20px 10px 20px 27px !important;
    .input-form-label {
        color: $primary-Sonic-Silver;
        opacity: 57%;
        text-transform: uppercase;
        margin-bottom: 5px;
        span {
            color: $severity-Electric-Red;
        }
    }
}
.forms-control-attachment{
    width: 100% !important;
    margin-bottom: 10px !important;
}
.attachement_modal__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px!important;
    line-height: 30px!important;
    color: $title-charcoal;
}
.attachment-lists{
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    span{
        @include text($Dark, 14px, 400, 24px, normal);
        span{
            color:$severity-Electric-Red!important;
        }
    }  
}
.add_section{
    color: $Tufts-Blue;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 16px;
    gap: 4px;
    cursor: pointer;
}
.add-icon{
    color: $Tufts-Blue;
    font-size: 17px!important;
}
.delete-icon{
    color: $Spanish-Gray!important;
    cursor: pointer;
}
.attachbtn_disabled{
    background-color: #bebebe!important;
    &:hover{
         cursor:not-allowed!important;
    }   
}
