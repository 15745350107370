@import "../Colors/colors";
@import "../Mixins/index";

.contents {
  padding: 24px 30px;
  overflow: auto;
  height: calc(100vh - 84px);
  scrollbar-width: none !important;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.cover__header {
  padding: 0px 6px;
}
.address {
  @include text($Dark, 20px, 600, 32px, normal);
}
.cover_border {
  border: 1px dashed $secondary-Gray;
}
.cover_pic {
  margin-top: 16px;
  min-width: 200px;
  min-height: 270px;
  position: relative;
  cursor: pointer;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 270px;
    gap: 8px;
    svg {
      color: $Tufts-Blue;
    }
    span {
      @include text($Dark, 14px, 400, 22px, normal);
    }
  }

  img {
    max-width: 100% !important;
    max-height: 270px !important;
    object-fit: cover;
  }
}
.image__label {
  display: flex;
  &:hover {
    cursor: pointer;
  }
}
.webcam-container {
  position: absolute;
  top: 0;
  left: 10;
  z-index: 2;
}
.cover_pic_edit {
  position: absolute;
  right: 9px;
  top: 8px;
  background: $CulturedF8;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 15px;
    color: $text-Black-Olive !important;
  }
}
.coverpic_delete {
  margin-top: 12px;
  display: flex;
  align-items: center;
  @include text($Charcoal-Grey, 14px, 400, 22px, normal);
  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    @include text($Charcoal-Grey, 14px, 400, 22px, normal);
    gap: 10px;
  }
  svg {
    font-size: 14px;
    color: $severity-Electric-Red !important;
  }
}
.status-heading {
  margin-top: 39px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: $primary-Spanish-Gray;
}
.status-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mb-6 {
  margin-bottom: 6px !important;
}
.p-custom {
  padding-right: 34px !important;
  padding-left: 39px !important;
}
.template-selection-modal-action {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 36px 34px 25px 39px !important;
  flex-direction: column;
  gap: 5px;
  button {
    width: 100%;
  }
}
#template_selection_error_text {
  color: $severity-Electric-Red;
}
#template_selection_error_text2 {
  color: $severity-Electric-Red;
}
// send report css
.send-report-modal .MuiPaper-root {
  max-width: 630px;
  width: 100%;
  .close-icon-container {
    display: flex;
    justify-content: flex-end;
    padding: 13px;
    .close-icon {
      cursor: pointer;
    }
  }
  h2 {
    text-align: center;
    @include text($title-charcoal, 22px, 400, 30px, normal);
    padding-bottom: 21px !important;
  }
  .send-report-content p {
    @include text($title-charcoal, 14px, 400, 22px, normal);
    text-align: center;
  }
}
.send-report-btn-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 25px 0px !important;
  .send-report-btn {
    @include text($secondary-white, 14px, 700, 16px, normal);
    background-color: $Tufts-Blue !important;
    border-radius: 3px;
    height: 48px !important;
    &:hover {
      background-color: $Tufts-Blue;
      @include text($secondary-white, 14px, 700, 16px, normal);
    }
  }
}
.delete-alert-modal {
  .MuiDialog-paper {
    padding: 50px 65px 36px 65px;
    align-items: center;
    width: 480px;
    max-width: 480px;
  }
  h2 {
    color: $text-Black-Olive;
    font-size: 22px;
    line-height: 30px;
    padding: 9px;
    font-family: "Source Sans Pro", sans-serif !important;
  }
  .MuiDialogActions-root {
    justify-content: center;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: $text-Black-Olive;
    margin-bottom: 12px;
    font-family: "Source Sans Pro", sans-serif !important;
  }
  .close-icon {
    color: $text-Black-Olive;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .popupBtn {
    width: 130px !important;
  }
}
.delete-modal-cancel-btn {
  width: 200px !important;
}
@media only screen and (max-width: 400px) {
  .delete-alert-modal {
    .MuiDialog-paper {
      padding: 50px 20px 36px 20px;
    }
    .popupBtn {
      width: 110px !important;
    }
  }
}
