@import "../Colors/colors";
@import "../Mixins/index";

.rl-header-title {
  -webkit-user-select: none;
  font-size: 11px !important;
  margin-top: 8px !important;
  font-weight: 700 !important;
  user-select: none !important;
  line-height: 16px !important;
  color: $text-Black-Olive !important;
  text-transform: capitalize !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.rl-header-close-icon {
  width: 24px !important;
  float: right !important;
  height: 24px !important;
  color: #A5A5A5 !important;
  &:hover {
    cursor: pointer;
    border-radius: 2px;
    background-color: $secondary-Smoke-White !important;
  }
}

.gallery-icon-hover {
  cursor: pointer;
  padding: 3px 3px 1px 3px;
  &:hover {
    border-radius: 2px;
    background-color: $secondary-Smoke-White !important;
  }
}

.rl-divider {
  color: $Mountain-Mist !important;
}

.rl-content-desc {
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-left: 10px !important;
  line-height: 15.08px !important;
  color: $Mountain-Mist !important;
}
.times_used_textfield {
  text-align: left;
  background-color: white;
  font-size: 13px;
  line-height: 13px;
  font-style: italic !important;
  padding-top: 10px;
  color: $text-Black-Olive !important;
}

.rl-filter {
  position: relative;
  color: #757575 !important;
  .MuiOutlinedInput-root {
    padding-left: 20px !important;
    border-radius: 4px !important;
    font-family: "Source Sans Pro", sans-serif !important;
    color: #757575 !important;
  }

  button {
    padding-right: 0px;
  }

  background-color: $secondary-white;

  & > div:first-child {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif !important;
  }

  .rl-filter-icon {
    position: absolute;
    left: 5px;
    top: 11px;
    cursor: pointer;
    font-size: 18px !important;
    color: $Mountain-Mist !important;
    padding: 0 !important;
  }
}

.rl-textfield {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 12px !important;
  color: $Sonic-Silver !important;
  padding-left: 4px !important;
}

.caption-title {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  margin-top: 1px !important;
  line-height: 15.08px !important;
  word-wrap: break-word !important;
  color: $text-Black-Olive !important;
  -webkit-user-select: none;
  user-select: none !important;
}
.caption-text {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  margin-top: 1px !important;
  line-height: 15.08px !important;
  color: $text-Black-Olive !important;
  -webkit-user-select: none;
  user-select: none !important;
}

.filter-text-selected {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  margin-top: 1px !important;
  line-height: 15.08px !important;
  color: $text-Black-Olive !important;
  -webkit-user-select: none;
  user-select: none !important;
}

.filter-text-option {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  margin-top: 1px !important;
  line-height: 15.08px !important;
  color: $text-Black-Olive !important;
  -webkit-user-select: none;
  user-select: none !important;
}

.check_icon_gallery {
  height: 50px !important;
  width: 50px !important;
  :hover {
    background-color: none;
  }
}

.rml-container {
  position: relative !important;
  height: 80vh !important;
  overflow-y: scroll !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.no_media_found_icon {
  height: 31px !important;
  width: 31px !important;
  color: $Mountain-Mist !important;
}

.mo_media_found {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: $Charcoal-Grey;
}

.no_media_found_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
