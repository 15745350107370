@import "../Colors/colors";
@import "../Mixins/index";

.coll_heading2 {
  width: 100%;
  padding: 14px 0px 10.3px 18px;
  font-style: normal;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #252525;
}
.review_media_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  flex-direction: row;
  padding: 0px 18px;
  padding-bottom: 5px;
}
.review_media {
  width: 108px;
  height: 80px;
}
.thumb_wrapper_review {
  width: 100%;
  cursor: pointer;
  max-width: 108px;
  max-height: 80px;
  position: relative;
  z-index: 0;
}
.imageViewer > .container > .footer {
  left: 1% !important;
  transform: translate(0, 0) !important;
}
.review_pannel {
  overflow: auto;
  height: calc(100vh - 83px);
  scrollbar-width: none !important;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none !important;
  }
}
