@import "../Colors/colors";
@import "../Mixins/index";

.filled_btn {
  @include text($secondary-white, 14px, 700, 16px, normal);
  background-color: $Tufts-Blue !important;
  border-radius: 3px;
  height: 48px !important;
  &:hover {
    background-color: $Tufts-Blue;
    @include text($secondary-white, 14px, 700, 16px, normal);
  }
}
.filled_btn:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}
.outlined_btn {
  @include text($Tufts-Blue, 14px, 700, 16px, normal);
  border-radius: 3px;
  height: 48px !important;
}
.back-btn {
  width: 101px !important;
  height: 34px !important;
  font-size: 13px !important;
  border: 1px solid $Spanish-Gray !important;
  color: $primary-Sonic-Silver !important;
  border-radius: 2px !important;
  text-transform: capitalize;
  &:hover {
    background: $primary-color !important;
    color: $secondary-white !important;
  }
  p {
    font-family: "Open Sans", sans-serif !important;
    font-size: 13px !important;
    font-weight: 600;
  }
}
.default-btn {
  background: $primary-color !important;
  width: 121px !important;
  height: 34px !important;
  font-size: 13px !important;
  border-radius: 2px !important;
  font-weight: 600 !important;
  color: $secondary-white !important;
  text-transform: capitalize;
}
.add-category-btn {
  min-width: 260px !important;
  height: 48px !important;
  border-radius: 3px !important;
  @include text($secondary-white, 14px, 700, 16px, normal);
  background-color: $Tufts-Blue !important;
}

.add-category-btn:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}

.go-back-btn {
  min-width: 260px !important;
  height: 48px !important;
  border-radius: 3px !important;
  @include text($Tufts-Blue, 14px, 600, 16px, normal);
  border: 1px solid $Tufts-Blue !important;
  margin-left: 3px !important;
  margin-top: 10px !important;
  // &:hover{
  //     background-color: $Tufts-Blue!important;
  //     color:$secondary-white !important
  // }
}
.loader_btn {
  @include text($secondary-white, 14px, 700, 16px, normal);
  background-color: $Tufts-Blue !important;
  border-radius: 3px;
  height: 48px !important;
  &:hover {
    background-color: $Tufts-Blue;
    @include text($secondary-white, 14px, 700, 16px, normal);
  }
}
.loader_btn:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}

.clear-selection-btn {
  @include text($Spanish-Gray, 16px, 600, 20.11px, normal);
  text-transform: none !important;
  text-decoration: underline !important;
  &:hover {
    background-color: transparent !important;
    color: $text-Black-Olive !important;
  }
}
