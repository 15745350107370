@import "../Colors/colors";
@import "../Mixins/index";

.tab_panel .MuiBox-root {
  padding: 0px !important;
}
.container {
  padding: 34px;
}
.cl_comment_title_section {
  padding: 15px 32px 0px 32px;
}
.comment_container {
  padding: 17px 30px 27px 30px !important;
  border: none !important;
  box-shadow: none !important;
  overflow: hidden !important;
}
.comment_container .MuiAccordionSummary-content {
  width: 100% !important;
}
.comment_container .MuiAccordionSummary-root {
  padding: 0px !important;
  margin-left: -18px;
  background-color: white !important;
  &:active {
    background-color: white !important;
  }
  @media only screen and (min-width: 1395px) and (max-width: 1500px) {
    margin-left: -30px !important;
  }
  @media only screen and (min-width: 1500px) and (max-width: 1800px) {
    margin-left: -40px !important;
  }
  @media only screen and (min-width: 900px) and (max-width: 1180px) {
    margin-left: -4px !important;
  }
}
.pdf_container {
  padding-left: 28px;
}
.cl_tabs .MuiTabs-indicator {
  display: none !important;
}
.lineDetails__tab {
  border-bottom: 1px solid $border-grey !important;
  .tab__label {
    @include text($Dark, 15px, 600, 18px, normal);
    text-transform: capitalize !important;
  }
  .comment_tab__label {
    @include text($Dark, 15px, 600, 18px, normal);
    text-transform: capitalize !important;
    padding: 13px 20px 0px 28px;
    cursor: pointer;
  }
  .tab__label.Mui-selected {
    @include text($Tufts-Blue, 15px, 600, 18px, normal);
  }
  .cl_tab_panel {
    padding-right: 4px !important;
    margin-right: 20px !important;
    padding-left: 4px !important ;
  }
  .captitalize {
    text-transform: unset !important;
  }
  .cl_tab_panel.active {
    border-bottom: 2px solid $Tufts-Blue !important;
  }
  .cl_tab_panel.inactive {
    border-bottom: 2px solid transparent !important;
  }
  .comment_tab__label_active {
    padding: 13px 20px 0px 28px;
    cursor: pointer;
    @include text($Tufts-Blue, 15px, 600, 18px, normal);
  }
}
.sticky_lineDetails__tab {
  position: sticky;
  // top: 65px;
  background-color: white !important;
  z-index: 3;
  border-bottom: 1px solid $border-grey !important;
  .tab__label {
    @include text($Dark, 15px, 600, 18px, normal);
    text-transform: capitalize !important;
  }
  .comment_tab__label {
    @include text($Dark, 15px, 600, 18px, normal);
    text-transform: capitalize !important;
    padding: 13px 20px 0px 28px;
    cursor: pointer;
  }
  .tab__label.Mui-selected {
    @include text($Tufts-Blue, 15px, 600, 18px, normal);
  }
  .cl_tab_panel {
    padding-right: 4px !important;
    margin-right: 20px !important;
    padding-left: 4px !important ;
  }
  .captitalize {
    text-transform: unset !important;
  }
  .cl_tab_panel.active {
    border-bottom: 2px solid $Tufts-Blue !important;
  }
  .cl_tab_panel.inactive {
    border-bottom: 2px solid transparent !important;
  }
  .comment_tab__label_active {
    padding: 13px 20px 0px 28px;
    cursor: pointer;
    @include text($Tufts-Blue, 15px, 600, 18px, normal);
  }
}
.line_details__add_btn {
  margin-top: 26px !important;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    cursor: pointer;
  }
  svg {
    color: $Tufts-Blue;
  }
  .line_details__add_text {
    @include text($Dark, 16px, 400, 20px, normal);
  }
}

.edit__media__add_btn {
  margin-top: 26px !important;
  // display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    cursor: pointer;
  }
  svg {
    color: $Tufts-Blue;
  }
  .line_details__add_text {
    @include text($Dark, 16px, 400, 20px, normal);
  }
}

.comment_details_add_media_btn {
  margin-top: 26px !important;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    cursor: pointer;
  }
  svg {
    color: $Tufts-Blue;
  }
  .line_details__add_text {
    @include text($Dark, 16px, 400, 20px, normal);
  }
}
.tab_container {
  overflow-y: auto;
  height: 310px;
  max-height: 500px;
  padding-right: 10px;
}
.line_progress {
  min-height: 200px;
  align-items: center;
  justify-content: center;
}
// .line_info{
//   text-align: center;
//   margin-top: 10px;
// }
// description
.line_detail_des_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px !important;
}
.mb_0 {
  margin-bottom: 0px !important;
}
.details_title {
  @include text($Dark, 22px, 600, 27px, normal);
}
.inner_details_title {
  @include text($Dark, 17px, 600, 21px, normal);
}
.details_action,
.cl_details_action {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  @include text($Dark, 16px, 400, 20px, normal);
  svg {
    font-size: 18px;
  }
}
.add_comment_btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: $Tufts-Blue;
  border-radius: 4px;
  @include text($Dark, 16px, 400, 20px, normal);
  svg {
    font-size: 18px;
  }
  button {
    color: #fff !important;
    height: 36px !important;
    max-width: 139px !important;
    @include text($secondary-white, 14px, 600, 20px, normal);
    text-transform: initial !important;
    display: flex;
    padding: 5px 10px !important;
  }
}
.inner_details_action {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  @include text($Dark, 16px, 400, 20px, normal);
  svg {
    font-size: 18px;
  }
}
.checkbox {
  span:nth-child(2) {
    @include text($Charcoal-Grey, 16px, 400, 26px, normal);
  }
}
.flex_start {
  align-items: flex-start !important;
  span:nth-child(2) {
    margin-top: 5px;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}
.dialog-container-new-option .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 430px !important;
  width: 100%;
}

.comment-dialog-non-narrative .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 1200px !important;
  width: 100% !important;
  max-height: 760px !important;
  height: 100% !important;
}

.comment-dialog-non-narrative h2 {
  padding: 16px 24px 0px 24px !important;
  color: $Dark !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
}

.non-narrative-comment-cancel {
  color: $Outer-Space !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  display: flex !important;
  height: 36px !important;
  padding: 8px 16px 8px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 2px !important;
  border: 1px solid $border-grey !important;
  background: $secondary-white !important;
  text-transform: none !important;
}

.non-narrative-comment-save {
  display: flex !important;
  height: 36px !important;
  padding: 8px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 2px !important;
  background: $secondary-Tufts-Blue !important;
  color: $secondary-white !important;
  font-feature-settings: "liga" off, "clig" off !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: none !important;
}

.non-narrative-comment-save:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
  border: 1px solid $secondary-Smoke-White;
}

.non-narrative-dialog-actions {
  display: flex !important;
  gap: 4px !important;
  margin: 10px !important;
}

.into-text-dialog .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 860px !important;
  width: 100%;
}
.dialog-container-new-option h2 {
  font-size: 18px !important;
}
.dialog-content-new-option {
  margin-top: 2px;
  padding-left: 40px !important;
  padding-right: 34px !important;
  padding-bottom: 0px !important;
  .input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 8px;
    span {
      color: $severity-Electric-Red;
    }
  }
}

.non-narrative-comment-label {
  color: $Outer-Space !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: uppercase !important;
  margin-bottom: 6px !important;
  opacity: 0.5671 !important;
}

.cl-new-comment-input-form-label {
  @include text($Outer-Space, 15px, 600, 20px, normal);
  margin-bottom: 6px;
  opacity: 57%;
}
.cl_add_btn_flex {
  display: flex;
  gap: 8px;
  span,
  p {
    @include text($Tufts-Blue, 14px, 600, 19px, normal);
  }
  svg {
    color: $Tufts-Blue;
    font-size: 21px;
  }
}
.comment-library-checkbox-container {
  display: flex;
  gap: 18px;
}
.cl_checkbox {
  span {
    @include text($text-Black-Olive, 14px, 400, 24px, normal);
  }
}
.disable_cl_checkbox {
  // pointer-events: none;
  opacity: 0.5;
  span {
    @include text($text-Black-Olive, 14px, 400, 24px, normal);
  }
}
.cl_checkbox .Mui-checked {
  color: $Tufts-Blue !important;
}
.comment-library-checkbox-container.hidden {
  visibility: hidden;
}
.comment-library-action-btn {
  display: flex;
  gap: 8px;
}

.review_image_container {
  display: flex;
  flex-wrap: wrap;
  .MuiGrid-item {
    max-width: 20% !important;
    display: flex;
    flex-wrap: wrap;
  }
  img {
    cursor: pointer;
    margin-right: 25px;
    max-width: 128px;
    max-height: 98px;
    gap: 30px;
  }
}
.video_thumb_container {
  position: relative;
  z-index: 0;
  .play_icon {
    color: $secondary-white !important;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.circular_progress_icon {
  color: $primary-color !important;
  height: 15px !important;
  width: 15px !important;
  position: absolute;
  top: 5px !important;
  right: 5px !important;
  transform: translate(-50%, -50%);
}
.thumb_wrapper {
  width: 100%;
  height: 180px;
  cursor: pointer;
  max-width: 128px;
  max-height: 98px;
}
.media_showmore__less {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  cursor: pointer;
  span {
    @include text($Dark, 16px, 400, 20px, normal);
    display: flex;
    align-items: center;
    gap: 5px;
  }
  svg {
    font-size: 22px;
  }
}
.input-form-label {
  @include text($Dark, 15px, 600, 18px, normal);
  margin-bottom: 8px;
}
.text-left {
  text-align: left !important;
}
// autocomplete
.forms-control {
  width: 100% !important;
  margin-bottom: 15px !important;
}
.forms-control-tag {
  width: 100% !important;
  margin-bottom: 11px !important;
}
// media gallery modal
.dialog-container-media .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 712px !important;
  width: 100%;
  scrollbar-width: thin; /* Firefox */
}
.dialog-container-media h2,
.gallery-modal-content h2 {
  font-size: 22px !important;
}
.dialog-container-media {
  max-width: 712px !important;
  scrollbar-width: thin;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 70%;
  // transform: translate(-50%, -50%);
}
.dialog-content-media {
  margin-top: 2px;
  margin-left: 2px;
  padding: 52px 72px 52px 72px !important;
  .input-form-label {
    color: $primary-Sonic-Silver;
    opacity: 57%;
    text-transform: uppercase;
    margin-bottom: 5px;
    span {
      color: $severity-Electric-Red;
    }
  }
}
.media-modal-action {
  display: flex !important;
  flex-direction: column !important;
  // align-items: center!important;
  justify-content: center !important;
  padding-top: 23px !important;
  padding-bottom: 43px !important;
  padding-left: 72px !important;
  padding-right: 82px !important;
  gap: 47px;
}
.media-edit-action {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 44px;
  span {
    display: flex;
    align-items: center;
    gap: 13px;
    @include text($Outer-Space, 16px, 400, 24px, normal);
    &:hover {
      cursor: pointer;
    }
    svg {
      font-size: 20px;
    }
  }
}
.media-galley-container {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 42px;
}

.add-media-nameplate {
  border: 2px dashed $secondary-Gray;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 202px;
}

.add-media-nameplate img {
  &:hover {
    cursor: pointer;
  }
}

.add-media-content_for_line_details {
  height: 125px;
  width: 100%;
  max-width: 155px;
  max-height: 125px;
  border: 2px dashed $secondary-Gray;
  // display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    svg {
      color: $primary-color;
    }
    span {
      @include text($Dark, 14px, 400, 17px, normal);
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.add-media-content_for_nameplate {
  height: 125px;
  width: 100%;
  max-width: 155px;
  max-height: 125px;
  // border: 2px dashed $secondary-Gray;
  // display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    svg {
      color: $primary-color;
    }
    span {
      @include text($Dark, 14px, 400, 17px, normal);
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.add-media-content {
  height: 125px;
  width: 100%;
  max-width: 155px;
  max-height: 125px;
  border: 2px dashed $secondary-Gray;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    svg {
      color: $primary-color;
    }
    span {
      @include text($Dark, 14px, 400, 17px, normal);
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.galley_image {
  width: 155px;
  height: 125px;
  margin-bottom: 20px;
  position: relative;
  img {
    height: 114px;
    width: 100%;
    max-width: 155px;
    max-height: 114px;
  }
  .image_description {
    display: flex;
    align-items: center;
    margin-top: 13px;
    @include text($Dark, 14px, 400, 17px, normal);
    gap: 8px;
    svg {
      font-size: 16px;
      color: $primary-color;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .image_description_content {
    @include text($Dark, 14px, 400, 17px, normal);
  }
  .play_icon {
    color: $secondary-white !important;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .media_delete_icon {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #d10505 !important;
    opacity: 0.8;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
.mt-0 {
  margin-top: 0px !important;
}
.forms-control_caption {
  padding: 10px 30px !important;
  width: 90% !important;
  textarea {
    border: 1px solid black !important;
    padding: 2px;
  }
}
// comments
.comment__container {
  padding: 17px 24px !important;
}
.comment_title {
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  @include text($Dark, 20px, 600, 25px, normal);
}
.narrative_comment_title {
  align-items: center !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  @include text($Dark, 19px, 600, 24px, normal);
}
.narrative_comment_title_expends {
  align-items: center !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  @include text($Dark, 19px, 600, 24px, normal);
}
.comment_title_single_cl {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  @include text($Dark, 19px, 600, 25px, normal);
}
.MuiFormControlLabel-root {
  margin: 0px !important;
  margin-top: 3px !important;
}
.comment_description {
  padding-left: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
}
.narrative_comment {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
  font-size: 19px;
  font-weight: 600;
  line-height: 30px !important;
  * {
    font-size: 19px !important;
    font-weight: 600;
    line-height: 30px !important;
  }
}
.comment_description_cl {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px !important;
  * {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px !important;
  }
}
.comment_detail_cl {
  max-width: 100%;
  // font-size: 16px;
  // font-weight: 400;
  line-height: 30px !important;
  * {
    // font-size: 16px;
    // font-weight: 400;
    line-height: 30px !important;
  }
}
.comment__container_divider {
  padding: 0px 24px 5px 24px;
}
.box_checked .checkbox_label {
  line-break: auto;
  // display: -webkit-box;
  max-width: 100%;
}
.box_unchecked .checkbox_label {
  line-break: auto;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
}
.pdf__container {
  padding: 22px 15px;
  .comment_title {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    overflow: hidden;
    .comment_title_single {
      max-width: 100%;
      @include text($Dark, 20px, 600, 25px, normal);
    }
    .comment_title_single_after_selecting {
      max-width: 100%;
      @include text($primary-color, 20px, 600, 25px, normal);
    }
  }
  .MuiFormControlLabel-root {
    margin: 0px !important;
    margin-top: 3px !important;
  }
  .MuiCheckbox-root {
    padding: 0px !important;
  }
  .comment_description {
    padding-left: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
  }
}

.pdf_inner__container {
  padding: 8px 28px;
  .comment_title {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    overflow: hidden;
    .comment_title_single {
      max-width: 100%;
      @include text($Dark, 20px, 600, 25px, normal);
    }
    .comment_title_single_after_selecting {
      max-width: 100%;
      @include text($primary-color, 20px, 600, 25px, normal);
    }
  }

  .MuiFormControlLabel-root {
    margin: 0px !important;
    margin-top: 3px !important;
  }
  .MuiCheckbox-root {
    padding: 0px !important;
  }
  .comment_description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
  }
}
.comment_detail_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px !important;
}
.inner_container {
  padding-left: 34px !important;
}

.pdf_inner_container {
  padding: 22px 15.5px 22px 15.5px !important;
}

.dateClass div {
  height: 40px;
}

.pdfInputBox div {
  height: 40px;
}
// rating
.rating_square_container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
  margin-top: 8px;
  .rating_square {
    width: 22px;
    height: 22px;
    display: flex;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rating_outside_square {
    width: 19px;
    height: 19px;
    display: flex;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 18px;
    height: 18px;
    color: $secondary-white;
  }
  svg {
    width: 18px;
    height: 18px;
    color: $secondary-white;
  }
  .rating_name_text {
    @include text($text-Black-Olive, 16px, 400, 28px, normal);
  }
  .rating_name_outside_text {
    @include text($text-Black-Olive, 13px, 400, 28px, normal);
  }
}

.rating_outside_square_outSide {
  width: 19px;
  height: 19px;
  display: flex;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
}
// rating
.rating_outside_square_container {
  display: flex;
  align-items: center;
  gap: 5px;
  .rating_outside_square {
    width: 19px;
    height: 19px;
    display: flex;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 15px;
    height: 15px;
    color: $secondary-white;
  }
  .rating_name_outside_text {
    @include text($text-Black-Olive, 13px, 400, 22px, normal);
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}
.clear_rating span {
  @include text($primary-color, 16px, 600, 21px, normal);
  text-transform: uppercase;
  margin-top: 15px;
  &:hover {
    cursor: pointer;
  }
}
//information
// sun editor styles
.sun-editor {
  border-radius: 3px;
  .se-btn-tray {
    background: $secondary-white;
  }
  .se-toolbar {
    border-radius: 3px;
  }
  .se-resizing-bar {
    display: none !important;
  }
  .se-menu-list {
    button {
      height: 28px;
    }
    li:nth-child(-n + 5) {
      margin: 0px 7px;
      button {
        background: $Lime-Gray;
      }
    }
    .active {
      background: $secondary-Tufts-Blue !important;
      color: $secondary-white !important;
    }
    li:nth-child(3),
    li:nth-child(4),
    li:nth-child(5) {
      padding-right: 16px;
      border-right: 1px solid $border-grey;
    }
    li:last-child {
      button {
        display: flex;
        align-items: center;
      }
    }
  }
}
.editor-class {
  background-color: #fff;
  padding: 1rem;
  border-top: 1px solid #ccc;
  min-height: 440px;
  font-size: 14px;
  color: $Dark-Charcoal2;
}
.toolbar-class {
  background: #ccc;
  margin-bottom: 0px !important;
}
.sun-editor {
  .se-wrapper {
    min-height: 140px !important;
  }
}
.into-text-dialog .sun-editor {
  .se-wrapper {
    min-height: 220px !important;
  }
}
.editor-dialog-title {
  padding: 36px 34px 5px 39px !important;
  text-align: center;
  @include text($Dark, 18px, 600, 24px, normal);
}

.non-narrative-comment-title {
  padding: 16px 0px 0px 0px !important;
  text-align: center;
  @include text($Dark, 18px, 600, 24px, normal);
}

.non-narrative-comment-tabs {
  min-height: 40px;
  height: 40px;
}

.non-narrative-comment-tab {
  color: #4282e2 !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: none !important;
}

.information_text {
  @include text($Sonic-Silver, 13px, 400, 16px, normal);
  margin-bottom: 4px;
}

//Location
.dialog-content-location {
  margin-top: 2px;
  padding: 20px 0px 20px 24px !important;
  .input-form-label {
    color: $primary-Sonic-Silver;
    opacity: 57%;
    span {
      color: $severity-Electric-Red;
    }
  }
}
.dialog-container-location .MuiDialog-paper {
  max-height: calc(100% - 20px) !important;
  max-width: 700px !important;
  padding: 9px 40px 10px 50px;
  width: 100%;
  overflow-x: hidden !important;
}

.dialog-container-location2 .MuiDialog-paper {
  max-height: calc(100% - 20px) !important;
  height: 60rem;
  max-width: 700px !important;
  padding: 9px 30px 10px 30px;
  width: 100%;
  overflow-x: hidden !important;
}

.edit-loc-item {
  justify-content: space-between;
  max-width: 134px;
  // word-break: break-all;
  // height: 35px;
  min-height: 35px;
  border-radius: 3px;
  margin-bottom: 13px;
  display: flex;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif !important;
  @include text($Tufts-Blue, 14px, 600, 16px, 0px);
  &:hover {
    // background: $Cyan-Blue;
    cursor: pointer;
    transition: background-color 100ms linear;
  }
}

.loc-item {
  max-width: 164px;
  padding-left: 10px;
  padding-right: 10px;
  // height: 35px;
  min-height: 35px;
  border: 1px solid $Tufts-Blue;
  border-radius: 3px;
  margin-bottom: 13px;
  display: flex;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif !important;
  @include text($Tufts-Blue, 14px, 600, 16px, 0px);
  &:hover {
    background: $Cyan-Blue;
    cursor: pointer;
    transition: background-color 100ms linear;
  }
}

.input-controll {
  input {
    height: 23px !important;
  }
}
.location-input {
  caret-color: transparent;
  cursor: pointer;
  input {
    cursor: pointer;
  }
}
.location-input-text {
  display: flex;
  align-items: center;
  // padding-left: 15px;
  // padding-left: 15px;
  min-height: 41px;
  border: 1px solid $Platinum;
  // border: 1px solid $Platinum;
  width: 100%;
  flex-wrap: wrap !important;
  border-radius: 4px;
  // margin-right: 15px;
  justify-content: space-between;
  .close-icon {
    margin-right: 10px;
    background: $Lime-Gray;
    padding: 2px 4px;
    border-radius: 50%;
    svg {
      border-radius: 50%;
      margin-top: 2px;
      color: $severity-Electric-Red;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.input-dialog {
  padding: 5px 45px 5px 24px;
  p:first-child {
    margin-bottom: 7px;
  }
}
.location-display-word {
  padding: 2px 4px;
}
.location-action {
  padding-left: 22px;
  width: 100%;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.location-edit-action {
  // padding-left: 22px;
  // width: 100%;
  // margin-right: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.location-edit {
  display: flex;
  align-items: center;
  gap: 12px;
  @include text($Dark, 16px, 400, 20px, 0px);
  svg {
    font-size: 16px;
  }
  &:hover {
    cursor: pointer;
  }
}
.w100 {
  width: 100%;
}
.loc-btn {
  margin-left: 17px !important;
  margin-right: 37px !important;
}
.overflow-x-hide {
  overflow-x: hidden !important;
}

.lineVisibility {
  visibility: hidden;
}

.rating_name_color {
  display: "flex";
  gap: "7px";
  align-items: "center";
  flex-wrap: "wrap";
  padding: "0px 10px";
  margin-top: "-3px";
  margin-bottom: "-3px";
  &:hover {
    cursor: pointer;
  }
}

.done_button svg {
  color: $primary-color;
}
.empty_comment_section {
  text-align: center;
  padding: 40px 0px 5px 0px;
  font-size: 13px;
}
.inner_done_title {
  @include text($primary-color, 17px, 600, 21px, normal);
}

.inner_details_title_after_selection {
  @include text($primary-color, 17px, 600, 21px, normal);
}
.level3_class .inner_details_title {
  @include text($Sonic-Silver, 17px, 400, 21px, normal);
}
.level3_class .inner_details_title_after_selection {
  @include text($primary-color, 17px, 400, 21px, normal);
}

.details_title_after_selection {
  @include text($primary-color, 22px, 600, 27px, normal);
}

.level3_checkBoxclass .comment_title .comment_title_single {
  max-width: 100%;
  @include text($Sonic-Silver, 20px, 400, 25px, normal);
}
.level3_checkBoxclass .comment_title .comment_title_single_after_selecting {
  max-width: 100%;
  @include text($primary-color, 20px, 400, 25px, normal);
}

.radio_button span {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  // color: #252525 !important;
  letter-spacing: normal !important;
  margin-top: -5px;
}

.radio_icon span {
  .MuiTypography-body1 {
    color: #d10505;
  }
}
.narrative_comment_bg {
  background-color: $white-smoke !important;
  padding: 15px 21px 15px 18px;
}
.narrative_summary_title {
  @include text($text-Black-Olive, 16px, 600, 22px, normal);
}
.narrative_summary_content {
  // color: $Charcoal-Grey;
  margin: 10px 0px;
  // font-size: 16px;
  // font-weight: 400;
  // * {
  // font-size: 16px;
  // font-weight: 400;
  // }
}
.cl_summary_rating_square_container {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  .rating_square {
    width: 19px;
    height: 18px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    font-size: 14px;
    color: $secondary-white;
  }

  .cl_rating_name_text {
    @include text($text-Black-Olive, 16px, 400, 32px, normal);
  }
}
.narrative_addmedia_title {
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 10px 0px;
  gap: 8px;
  svg {
    color: $Tufts-Blue;
    font-size: 18px;
  }
  span {
    @include text($text-Black-Olive, 14px, 600, 22px, normal);
  }
}
.narrative_comment_media_container {
  img {
    width: 132px;
    height: 90px;
  }
}

.line-details-parent-div {
  display: flex;
  flex-wrap: wrap;
}
.line-details-child-div {
  flex: 1 0 200px; /* This means that the child divs will grow and shrink as needed, but will have a minimum width of 200px. */
  box-sizing: border-box;
  width: 0%;
}
.editClDescIcon {
  font-size: 16px !important;
  color: $primary-Sonic-Silver !important;
}
.editClDescText {
  @include text($title-charcoal, 14px, 400, 20px, normal);
}
.cl_popper_menu {
  display: flex;
  gap: 10px;
  &:hover {
    background: transparent !important;
  }
}

.line-details-container {
  height: calc(100vh - 88px) !important;
  overflow-y: auto !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.tabs_container {
  overflow: auto !important;
  height: calc(100vh - 100px - 30px) !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.tabs_container1 {
  overflow: auto !important;
  height: calc(100vh - 84px) !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: hidden !important;
  }
}

.view-all-button {
  font-size: 12px !important;
  color: $text-Black-Olive !important;
  text-transform: none !important;
  padding: 0px !important;
  span {
    @include text($text-Black-Olive, 16px, 400, 22px, normal);
  }
}
