@import "../../../Assets/Styles/Colors/colors";
@import "../../../Assets/Styles/Mixins/index";

.report_library {
    padding: 6px 15px;
    height: 61px;
}

.add_media_tab {
    padding: 15px 16px 6px 16px !important;
}

.library_tab div div {
    height: 36px !important;
}

.library_tab div span {
    height: 0px !important;
}

.report_library__tab {
    padding: 15px 6px 6px 16px !important;

    .tab__label {
        min-width: 18px !important;
        border-radius: 2px;
        min-height: 9px !important;
        border: 1px solid $border-grey !important;
        @include text($Outer-Space, 15px, 400, 18px, normal);
        text-transform: capitalize !important;

        &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.04) !important;
        }
    }

    .comment_tab__label {
        @include text($Dark, 15px, 600, 18px, normal);
        text-transform: capitalize !important;
        padding: 13px 20px 0px 28px;
        cursor: pointer;
    }

    .tab__label.Mui-selected {
        background-color: $Tufts-Blue;
        @include text(white, 15px, 400, 18px, normal);

        &:hover {
            cursor: pointer;
            background-color: $Tufts-Blue !important;
            @include text(white, 15px, 400, 18px, normal);
        }
    }

    .cl_tab_panel {
        padding-right: 2px !important;
        margin-right: 5px !important;
    }

    .comment_tab__label_active {
        padding: 13px 20px 0px 28px;
        cursor: pointer;
        @include text($Tufts-Blue, 15px, 600, 18px, normal);
    }
}

.all_filter_button {
    border: 1px solid $border-grey !important;
    text-transform: none !important;
    color: #737373 !important;
}

.unused_media {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    flex-direction: row;
    padding: 0px 18px;
    padding-bottom: 5px;
}

.report_library_cancel_button {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    font-family: "Source Sans Pro" !important;
    color: $severity-Electric-Red !important;
    margin: 10px !important;
}

.report_library_retry_button {
    background-color: $primary-color !important;
    width: 100%;
    max-width: 346px;
    height: 48px !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    font-family: "Source Sans Pro" !important;
    color: #FFFFFF !important;
    margin: 7px !important;
}

.report_library_error_modal_done_button {
    width: 100%;
    max-width: 346px;
    height: 48px !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    font-family: "Source Sans Pro" !important;
    color: #4282E2 !important;
    margin: 7px !important;
}

.upload_progress {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $Dark !important;
    font-family: "Source Sans Pro" !important;
    padding: 3px !important;
}

.progress_box {
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    background-color: $secondary-white !important;
    color: $Dark !important;
    padding: 15px !important;
    text-align: center !important;
    position: sticky;
    box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.2) !important;
    border-radius: 18px 18px 0px 0px !important;

    @media (max-width: 768px) {
        margin-top: 50% !important;
    }

    @media (max-width: 480px) {
        padding: 10px !important;
    }
}

.bulk_upload_circular_bar {
    width: 20px !important;
    height: 20px !important;
    top: 14px !important;
    right: 19px !important;
    position: absolute !important;

    svg {
        width: 20px !important;
        height: 20px !important;
    }
}

.filter_container {
    padding-left: 6px !important;
}

.filter_container div {
    max-height: 36px !important;
}

.report-filter-text-selected {
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    margin-top: 1px !important;
    line-height: 15.08px !important;
    color: $text-Black-Olive !important;
    -webkit-user-select: none;
    user-select: none !important;
}

.report-filter-text-option {
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    margin-top: 1px !important;
    line-height: 15.08px !important;
    color: $text-Black-Olive !important;
    -webkit-user-select: none;
    user-select: none !important;
}

.report-media-library-add-media-button {
    box-shadow: none !important;
    font-size: 14px !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 600 !important;
    background-color: $primary-color !important;
}

.disabled-report-media-library-add-media-button {
    box-shadow: none !important;
    font-size: 14px !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 600 !important;
    background-color: "#e5e5e5" !important;
    color: #FFFFFF !important;

    &:hover {
        cursor: not-allowed;
        box-shadow: none !important;
        font-size: 14px !important;
        font-family: "Source Sans Pro", sans-serif !important;
        font-weight: 600 !important;
        background-color: "#e5e5e5" !important;
        color: #FFFFFF !important;
    }
}

.filter_value {
    font-size: 12px !important;
}

.report_media {
    width: 154px !important;
    height: 143px !important;
    margin: 6px 0px !important;
}

@media only screen and (min-width: 900px) and (max-width: 1180px) {

    // 110% screen width
    .report_media {
        width: 120px !important;
        margin: 6px 0px !important;
    }
}

@media only screen and (min-width: 1395px) and (max-width: 1500px) {

    // 90% screen width
    .report_media {
        width: 176px !important;
        margin: 6px 0px !important;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1800px) {

    // 80% screen width
    .report_media {
        width: 190px !important;
        margin: 6px 0px !important;
    }
}

.report_video_thumb_container {
    position: relative;
    z-index: 0;

    .report_media_play_icon {
        color: $secondary-white !important;
        font-size: 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.report_media_thumb_wrapper_review {
    display: flex !important;
    align-items: center !important;
    cursor: pointer;
    z-index: 0;
}

.check_circle_icon {
    width: 24px !important;
    height: 24px !important;
    color: #20CA31 !important;
    margin-left: 50% !important;
    transform: translateX(-50%) !important;

    svg {
        width: 24px !important;
        height: 24px !important;
    }
}

.error_icon {
    width: 24px !important;
    height: 24px !important;
    color: #E02020 !important;
    transform: translateX(-50%) !important;
    margin: 10px 0px 15px 50% !important;

    svg {
        width: 24px !important;
        height: 24px !important;
    }
}

.no_media_found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $Mountain-Mist;
    text-align: center;
    padding-left: 38%;
    padding-top: 4%;
    svg {
        width: 31px !important;
        height: 31px !important;
    }

    p {
        font-size: 16px !important;
        margin-top: 8px;
    }
}

.linear_progress_bar {
    width: 30% !important;
    left: 35% !important;
    margin: 10px !important;
}

.report_media_delete_icon{
    border-radius:4px !important;
    cursor: pointer;
    position: absolute;
    padding: 6px;
    right: 30px;
    bottom: 26px;
    height: 32px;
    width: 36px;
    border-radius: 2px;
    color: #D63D3D !important;
    background-color: #FFFFFF !important;
    border: 1px solid #D5D5D5 !important;
}