@import "../Colors/colors";

.mark-as-complete-modal-main {
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: hidden;
    width: 2px;
  }
}
.mark-as-complete-modal {
  top: 50%;
  left: 50%;
  outline: 0;
  padding: 4px;
  margin-bottom: 20%;
  position: relative;
  border-radius: 8px;
  max-width: 517px !important;
  transform: translate(-50%, -25%);
  background-color: $secondary-white;
  box-shadow: 0px -8px 24px $Tich-Black;
}
.modal-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $Dark;
}
.modal-content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 333px;
  line-height: 24px;
  color: $Dark;
}
