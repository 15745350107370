@import "../../../Assets/Styles/Colors/colors";
@import "../../../Assets/Styles/Mixins/index";

.assign_color_icon {
  font-size: 20px !important;
  height: 20px !important;
  width: 20px !important;
}

.ci-table-head,
.ci-table-head span {
  padding: 18px !important;
  @include text($Outer-Space, 14px, 600, 20px, normal);
}

.edit-color-indicator-dialog-title {
  padding: 21px 34px 10px 19px !important;
  text-align: left;
  @include text($Dark, 24px, 600, 32px, normal);
}

.ci-row-status {
  button {
    padding: 0px !important;
  }
}

.ci-exp-col {
  padding-top: 4px !important;
  vertical-align: text-top !important;
}

.ci-title-col {
  width: 142px !important;
  vertical-align: text-top !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
}

.ci_tags_textfield div input {
  width: 508px !important;
  background-color: #fff !important;
  border-color: #e5e8e8 !important;
  height: 0px !important;
}

.ci_title {
  font-family: "Source Sans Pro", sans-serif !important;
  color: #333;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
}

.ci_add_button {
  color: #4282e2 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: none !important;
  &:hover {
    background-color: transparent !important;
  }
}

.ci-save-btn {
  width: 119px;
  height: 34px;
  gap: 0px;
  border-radius: 2px 0px 0px 0px;
  // opacity: 0px;
  box-shadow: none !important;
  &:hover {
    background-color: #4282e2 !important;
  }
}

.ci-delete-btn {
  width: 36px;
  height: 32px;
  display: flex;
  justify-content: center;
  border-radius: 3px !important;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d5d5d5;
}

.hex-color-title-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  font-style: normal;
  letter-spacing: 0px;
  padding-top: 1px;
}

.edit-color-indicator-action {
  padding-left: 34px !important;
  padding-right: 28px !important;
  display: flex !important;
  justify-content: space-between !important;
  height: 64px !important;
  .ci-cancel-btn {
    width: 101px !important;
    height: 34px !important;
    @include text($Spanish-Gray, 13px, 600, 24px, normal);
    border: 1px solid $button-border;
    border-radius: 1px !important;
    letter-spacing: 0.5px !important;
    &:hover {
      background-color: transparent !important;
    }
  }

  .ci-add-btn {
    width: 170px !important;
    height: 34px !important;
    background: $Tufts-Blue !important;
    @include text($secondary-white, 13px, 600, 24px, normal);
    border: 1px solid $Tufts-Blue;
    border-radius: 1px !important;
    text-transform: unset !important;
    letter-spacing: 0.5px !important;
  }

  .ci-add-btn:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
    border: 1px solid $secondary-Smoke-White;
  }
  .ci-edit-comment-btn {
    width: 157px !important;
    height: 34px !important;
    background: $Tufts-Blue !important;
    @include text($secondary-white, 13px, 600, 24px, normal);
    border: 1px solid $Tufts-Blue;
    border-radius: 1px !important;
    text-transform: unset !important;
    letter-spacing: 0.5px !important;
  }

  .ci-edit-comment-btn:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
    border: 1px solid $secondary-Smoke-White;
  }
}

.edit-color-indicator-btn {
  display: flex;
  gap: 8px;
}

.assign_color_bordered__text {
  border: 1px solid $border-grey !important;
  border-radius: 9px !important;
  padding: 12px 15px;
  margin-bottom: 13px !important;
  position: relative;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
    background-color: transparent !important;
  }
}

.assign_color_bordered__text.assign_color_selected {
  border: 1px solid $primary-color !important;
  background-color: $Cyan-Blue !important;
  .assign_color_selected_icon {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 24px !important;
    color: $primary-color;
    display: block !important;
  }
}

.edit-icon-text-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.edit-icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
  display: flex;
}

.bordered__text__content {
  display: flex;
  align-items: center;
  gap: 8px;
}
